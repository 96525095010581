import React, { useState, useEffect, useCallback } from 'react'
import qs from 'qs'
import axios from 'axios'
import PropTypes from 'prop-types'
import { deserialize } from 'deserialize-json-api'
import { get, round, replace, isUndefined } from 'lodash'

import SEO from '../../atoms/Seo'
import JsonLD from '../../atoms/JsonLD'
import Navbar from '../../organisms/Navbar'
import CityPrograms from '../../organisms/CityPrograms'
import CityTopSection from '../../organisms/CityTopSection'
import { API_SALES_URL } from '../../../config'

const DepartmentTemplate = ({ pageContext, location }) => {
  const [loading, updateLoading] = useState(true)
  const [programs, updatePrograms] = useState([])
  const [total, updateTotal] = useState(0)

  const id = get(pageContext, 'id', '')
  const region = get(pageContext, 'region', '')
  const department = get(pageContext, 'name', '')
  const departmentCode = get(pageContext, 'code', '')

  const search = qs.parse(replace(get(location, 'search'), '?', ''))
  const page = get(search, 'page')
  const [currentPage, updatePage] = useState(isUndefined(page) ? 1 : parseInt(page))

  const title = `Programme Neuf - ${department} - ${departmentCode}`
  const description = `Tous les appartements neufs à vendre à ${department} du 2 au 5 pièces. Prix direct Promoteur. Terrasse, balcon, parking.`
  const linkedData = {
    '@context': 'http://schema.org',
    '@type': 'Project',
    name: 'Folhomee',
    description,
    parentOrganization: {
      '@type': 'Organization',
      name: 'Folhomee',
      location: {
        '@type': 'PostalAddress',
        streetAddress: '44 Rue du Chemin Vert',
        addressLocality: 'Paris',
        postalCode: '75011'
      }
    },
    keywords: 'Programme immobilier, Folhomee, page département, recherche immobilier, accompagnement'
  }

  const fetchPrograms = useCallback(async () => {
    updateLoading(true)

    try {
      const { data } = await axios.get(`${API_SALES_URL}/api/programs/search`, {
        params: {
          departments: [id],
          lotType: get(search, 'nature'),
          minRooms: get(search, 'minRooms'),
          maxRooms: get(search, 'maxRooms'),
          maxPrice: get(search, 'budget'),
          minSurface: get(search, 'surface'),
          deliveryYear: get(search, 'deliveryYear'),
          deliveryQuarter: get(search, 'deliveryQuarter'),
          'page[number]': currentPage,
          'page[size]': 15
        }
      })

      updatePrograms(get(deserialize(data), 'data'))
    } catch (err) {
      console.log(err)
    } finally {
      updateLoading(false)
    }
  }, [search, currentPage])

  const fetchCount = useCallback(async () => {
    try {
      const { data } = await axios.get(`${API_SALES_URL}/api/programs/count_search`, {
        params: {
          departments: [id],
          lotType: get(search, 'nature'),
          minRooms: get(search, 'minRooms'),
          maxRooms: get(search, 'maxRooms'),
          maxPrice: get(search, 'budget'),
          minSurface: get(search, 'surface'),
          deliveryYear: get(search, 'deliveryYear'),
          deliveryQuarter: get(search, 'deliveryQuarter')
        }
      })

      updateTotal(get(data, 'programs', 0))
    } catch (err) {
      console.log(err)
    }
  }, [search])

  useEffect(() => {
    fetchPrograms()
  }, [currentPage])

  useEffect(() => {
    fetchCount()
    fetchPrograms()
  }, [location])

  return (
    <>
      <SEO
        title={title}
        location={location}
        description={description}>
        <JsonLD>
          {linkedData}
        </JsonLD>
      </SEO>
      <Navbar location={location} />
      <CityTopSection
        location={{
          type: 'department',
          department: [{
            id,
            name: department,
            departmentCode
          }],
          breadcrumb: {
            region,
            department,
            departmentCode
          }
        }}
        loading={loading}
        programs={programs} />
      <section
        id={`${department} liste des programmes neufs`}>
        <CityPrograms
          city={department}
          page={currentPage}
          loading={loading}
          maxPage={round(total / 15)}
          programs={programs}
          updatePage={updatePage} />
      </section>
    </>
  )
}

DepartmentTemplate.propTypes = {
  location: PropTypes.object,
  pageContext: PropTypes.object.isRequired
}

export default DepartmentTemplate
